@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

/* main_loader styling */
.main_loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader_image_width {
  width: 30%;
}

/* / common classes for styling / */
.main_logo_container {
  width: 45%;
}

.fluid_container {
  width: 85%;
  margin: auto;
}

.common_btn {
  background: linear-gradient(to right, #1d9a1b, #a5ee55);
  height: 45px;
  color: #fff;
  padding: 0 3rem;
  border: none;
  border-radius: 5px;
  font: 500 1rem "Poppins", sans-serif;
  cursor: pointer;
}

.medium_font {
  font: 800 2rem "Poppins", sans-serif;
}

.normal_font {
  font: 400 1rem/2rem "Poppins", sans-serif;
}

.small_font {
  font: 400 1rem "Poppins", sans-serif;
}

/* / Navbar styling / */
.nav_div {
  box-shadow: 0px 10px 20px #0000000d;
  opacity: 0.95;
}

.drawer_content {
  height: 30vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drawer_content > p > a {
  color: #fff;
  font: 600 1rem "Poppins", sans-serif;
}

.ant-drawer-body {
  background: linear-gradient(to right, #1d9a1b, #a5ee55);
}

.ant-drawer-content {
  width: 300px !important;
  position: absolute !important;
  right: 0 !important;
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.display_none2 {
  display: none;
}

.nav_logo_width {
  width: 50%;
}

.nav_content {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text_content > a {
  padding-right: 2.5rem;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  font: 500 1rem "Poppins", sans-serif;
}

/* / Main screen styling / */
.left_col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right_col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left_section > h1 {
  font-size: 2.6rem;
  color: #1d9a1b;
}

/* / About section Styling / */
.about_div {
  text-align: center;
  margin: 3rem 0;
}

/* / contact styling / */
.input_div {
  /* margin: 2rem 0; */
}

.inputFeilds {
  border: none;
  background: #f9f9f9;
  height: 50px;
  /* width: 100%; */
}

.inputFeilds::placeholder {
  color: #000;
}

.text_area_feilds {
  border: none;
  background: #f9f9f9;
  height: 150px !important;
}

.text_area_feilds::placeholder {
  color: #000;
}

.PhoneInput {
  background: #f9f9f9;
}

.PhoneInput,
.inputText {
  border-radius: 5px;
  height: 50px;
  padding: 4px 11px;
}

.PhoneInput {
  align-items: center;
  display: flex;
}

.PhoneInputInput {
  border: none;
  border-radius: 5px;
  height: 50px;
  outline: none;
  padding: 4px 11px;
  background: #f9f9f9;
}

.ant-select-single .ant-select-selector {
  height: 45px !important;
  outline: none !important;
  background: #f9f9f9 !important;
  border: none !important;
  border-radius: 5px !important;
  padding: 4px 11px !important;
}

.ant-select-selection-placeholder {
  color: #000 !important;
}

.vid_div {
  position: relative;
  /* filter: blur(5px); */
}

.blur_div {
  position: absolute;
  top: 0;
  /* background: #00000086; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* footer styling  */
.main_footer {
  padding: 3rem 0 6rem 0;
  background-color: #f9f9f9;
  /* text-align: center; */
}

.Last_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.5rem 0;
  background: linear-gradient(to right, #1d9a1b, #a5ee55);
  /* text-align: center; */
}

.footer_image_width {
  width: 35%;
}

.footer_image_width2 {
  width: 40%;
}

.btn_width {
  width: 80px;
  cursor: pointer;
}

@media screen and (min-width: 1600px) {
  .text_content > a {
    padding-right: 4.5rem;
    font-size: 1.4rem;
  }

  .left_section > h1 {
    font-size: 4.8rem;
    color: #1d9a1b;
  }

  .normal_font {
    font: 400 1.5rem/3.8rem "Poppins", sans-serif;
  }

  .small_font {
    font: 400 1.5rem "Poppins", sans-serif;
  }

  .common_btn {
    height: 55px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 991px) {
  .text_content > a {
    display: none;
  }

  .display_none {
    display: none;
  }

  .display_none2 {
    display: block;
  }

  .left_col {
    margin-top: 3rem;
  }
  .footer_image_width{
    width: 60%;
  }
  .main_footer{
    text-align: center;
  }
}

@media screen and (max-width: 550px) {
  .nav_logo_width {
    width: 100%;
  }

  .loader_image_width {
    width: 50%;
  }

  .footer_image_width {
    width: 70%;
  }

  .footer_image_width2 {
    width: 80%;
  }

  .left_section > h1 {
    font-size: 1.5rem;
    color: #1d9a1b;
  }

  .medium_font {
    font-size: 1.2rem;
  }

  .btn_width {
    width: 50px;
  }
  ._mt{
    margin-top: 1rem;
  }
}
